import React from 'react';
import { client, URL } from '../../api/apolloClient';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';

const OrderList = React.lazy(() => import('@izi-logistics/common/lib/components/Order/List/Customer/List'));
const OrderListV1 = React.lazy(() => import('@izi-logistics/common/lib/components/Order/List/Customer/ListV1'));

export const AllOrderListV1 = function (props) {
  return (
    <PreloadingComponent>
      <OrderListV1 {...props} bookingRestricted config={{ URL, client }} />
    </PreloadingComponent>
  );
};
export default function AllOrderList(props) {
  if (props.v1) {
    return (
      <PreloadingComponent>
        <OrderListV1 {...props} config={{ URL, client }} />
      </PreloadingComponent>
    );
  }
  return (
    <PreloadingComponent>
      <OrderList {...props} config={{ URL, client }} />
    </PreloadingComponent>
  );
}
