import React from 'react';
import { CompletePaperLayout } from '@izi-logistics/common/lib/components/CompletePaperLayout';
import OrderList from './pages/Orders/List';
import { Redirect, Route, Switch } from 'react-router';
import SetPassword from './pages/Auth/SetPassword/SetPassword';
import SidebarMenu from './components/common/SidebarMenu';
import { client } from './api/apolloClient';
import Account from './pages/Account/Account';
import ChatContentComponent from './pages/Chat';
import MainTabsRoutes, { WarehousePage } from './pages/Warehouse';
import { FetchOrderQuoteStatsDetails } from '@izi-logistics/common/lib/graphql';
import Issues from './pages/Issues';

const WarehouseRoute = () =>
  (
    <Switch>
      <Route path="/warehouses/:warehouseId" component={(p: any) => <MainTabsRoutes {...p} config={{ client }} />} />
      <Route exact path="/warehouses" component={(p: any) => <WarehousePage {...p} config={{ client }} />} />
    </Switch>
  ) || null;
export default function RootRoutes() {
  const routes = (
    <Switch>
      <Route exact path="/change-password" component={SetPassword} />
      <Route exact path="/account/details" component={Account} />
      <Route exact path="/account/settings" component={Account} />
      <Route path="/account">
        <Redirect to={'/account/details'} />
      </Route>
      <Route path="/warehouses" component={WarehouseRoute} />
      <Route path="/chats" component={ChatContentComponent} />
      <Route exact path="/issues" component={Issues} />
      <Route exact path="/archive" component={(p: any) => <OrderList {...p} key={p.match.url} />} />
      <Route
        exact
        path="/in-progress"
        component={(p: any) => <OrderList {...p} key={p.match.url} statsQuery={FetchOrderQuoteStatsDetails} />}
      />
      <Route exact path="/open-orders" component={(p: any) => <OrderList {...p} key={p.match.url} />} />
      <Route path="*">
        <Redirect to="/in-progress" />
      </Route>
    </Switch>
  );
  return <CompletePaperLayout config={{ client }} sideBarMenuContent={<SidebarMenu />} pageContent={routes} />;
}
