import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Login from './pages/Auth/Login/Login';
import WaitingPage from '@izi-logistics/common/lib/pages/WaitingPage';
import { CompletePaperLayout } from '@izi-logistics/common/lib/components/CompletePaperLayout';
import ResetPassword from './pages/Auth/ResetPassword/ResetPassword';
import SetPassword from './pages/Auth/SetPassword/SetPassword';
import RootRoutes from './RootRoutes';
import { NOTIFICATION_ACTIONS } from '@izi-logistics/common/lib/notifications';
import { GlobalNotificationsSocket, GlobalSocketClient } from '@izi-logistics/common/lib/components/Socket';
import { client, URL } from './api/apolloClient';
import { GlobalChannelSocket } from '@izi-logistics/common/lib/pages/Chat/stores/Channel';
//
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';
import OrderForm from './pages/Orders/OrderForm';
import { AllOrderListV1 } from './pages/Orders/List';
import SidebarMenu from './components/common/SidebarMenu';
import PublicPage from '../src/pages/Public'; //

const QuoteDetails = React.lazy(() => import('@izi-logistics/common/lib/pages/Quotes/Details'));
export default ({ store }: any) => {
  const accountStore = store.accountStore;
  if (accountStore.isWatingScreen) {
    return (
      <Switch>
        <Route path="*" component={WaitingPage} />
      </Switch>
    );
  }
  if (accountStore.isLoggedIn) {
    return (
      <GlobalSocketClient config={{ URL, client }}>
        <GlobalNotificationsSocket>
          <GlobalChannelSocket client={client}>
            <Switch>
              <Route path="/public" component={PublicPage} />
              <Route
                exact
                path="/quote-details/:quoteId"
                component={(props: any) => (
                  <PreloadingComponent>
                    <QuoteDetails
                      {...props}
                      config={{
                        URL,
                        client,
                        variables: {
                          fields: [{ name: 'parent', value: props.match.params.quoteId }],
                        },
                      }}
                    />
                  </PreloadingComponent>
                )}
              />
              <Route exact path="/orders/:orderId" component={OrderForm} />
              <Route path="/change-password" component={SetPassword} />
              <Route path="/" component={RootRoutes} />
            </Switch>
          </GlobalChannelSocket>
        </GlobalNotificationsSocket>
      </GlobalSocketClient>
    );
  }
  const urlSearch = new URLSearchParams(window.location.search);
  const bookingAccessToken = [NOTIFICATION_ACTIONS.LINK_TO_BOOK, NOTIFICATION_ACTIONS.LINK_TO_VIEW_ORDER].find((e) =>
    urlSearch.get(e)
  );
  return (
    <Switch>
      <Route path="/public" component={PublicPage} />
      <Route exact path="/login" component={Login} />

      {bookingAccessToken &&
        [{ path: '/in-progress' }, { path: '/open-orders' }].map((e) => (
          <Route
            {...e}
            component={(props) => (
              <CompletePaperLayout
                {...props}
                config={{ client }}
                sideBarMenuContent={<SidebarMenu />}
                pageContent={<AllOrderListV1 {...props} />}
              />
            )}
          />
        ))}
      <Route path="/change-password" component={SetPassword} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route path="*">
        <Redirect to="/login" />
      </Route>
    </Switch>
  );
};
