import React from 'react';
import { inject } from 'mobx-react';
import RestoreOutlinedIcon from '@material-ui/icons/RestoreOutlined';
import { BasicSideBar } from '@izi-logistics/common/lib/components';
import { BasicSideBarMenuItem } from '@izi-logistics/common/lib/components';
import { MainStoreProps } from '@izi-logistics/common/lib/models';
import { NOTIFICATION_ACTIONS } from '@izi-logistics/common/lib/notifications';
import { useTranslation } from 'react-i18next';
import { KEYS } from '@izi-logistics/common/lib/i18n';
import { WarehouseIcon, InProgressIcon } from '@izi-logistics/common/lib/components/icons';
// import ChannelChatIcon from '@izi-logistics/common/lib/pages/Chat/ChannelChatIcon';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { client } from '../../api/apolloClient';

const path = window.location.search;
const pathName = window.location.pathname.toLowerCase();
const urlSearch = new URLSearchParams(path);
const hasBooking = urlSearch.get(NOTIFICATION_ACTIONS.LINK_TO_BOOK);
const hasView = urlSearch.get(NOTIFICATION_ACTIONS.LINK_TO_VIEW_ORDER);
const search = path + '';
const SidebarMenu = inject('store')(({ store }: MainStoreProps) => {
  const langMenu = useTranslation(KEYS.MENU);

  const menu: BasicSideBarMenuItem[] = [
    {
      icon: <DescriptionOutlinedIcon />,
      title: langMenu.t('menu_sidebar_open_orders_title'),
      path: '/open-orders',
      _id: 'menu-sidebar-open-orders',
    },
    {
      icon: (
        <span>
          <InProgressIcon title={langMenu.t('menu_sidebar_in_progress_title')} />
        </span>
      ),
      title: langMenu.t('menu_sidebar_in_progress_title'),
      path: '/in-progress' + (!store.accountStore.isLoggedIn && hasBooking ? search : ''),
      _id: 'menu-sidebar-in-progress',
    },
  ];
  if (!store.accountStore.isLoggedIn && (hasView || hasBooking)) {
    if (pathName.match('open-orders')) {
      menu.splice(1, 1);
    } else {
      menu.splice(0, 1);
    }
  }
  if (store.accountStore.isLoggedIn) {
    menu.push(
      ...[
        {
          icon: <RestoreOutlinedIcon />,
          title: langMenu.t('menu_sidebar_archive_title'),
          path: '/archive',
          _id: 'menu-sidebar-archive',
        },

        {
          icon: (
            <span>
              <WarehouseIcon title={langMenu.t('menu_sidebar_warehouse')} />
            </span>
          ),
          title: langMenu.t('menu_sidebar_warehouse'),
          path: '/warehouses',
          _id: 'menu-sidebar-warehouses',
        },
        // {
        //   icon: (
        //     <span>
        //       <ChannelChatIcon title={langMenu.t('menu_sidebar_chat_title')} />
        //     </span>
        //   ),
        //   title: langMenu.t('menu_sidebar_chat_title'),
        //   path: '/chats',
        // },
      ]
    );
  }
  return <BasicSideBar menu={menu} config={{ client }} />;
});
export default SidebarMenu;
